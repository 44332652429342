import { useGlobal } from 'reactn';
import { useEffect } from 'react';
import Mint from '../components/Mint';
import FadeIn from 'react-fade-in';
import heroImage from '../images/hero1.jpg';
import heroImage2 from '../images/hero2.jpg';
import heroImage3 from '../images/hero3.jpg';
import heroImage4 from '../images/hero4.jpg';
import heroImage5 from '../images/hero5.jpg';
import Countdown from '../components/Countdown';
import moment from 'moment-timezone';




export default function PageMint(props){

    const [maxSupply] = useGlobal('maxSupply');
    const [totalSupply] = useGlobal('totalSupply');
    const [infuraReady] = useGlobal('infuraReady');

    useEffect(() => {
        window.VanillaTilt.init(document.querySelectorAll('[data-tilt]'),{
            gyroscope: true,
        });
    });

    return (
    
        <div className="col-1 h-screen">
        
        
            <div className="w-lg md:grid md:grid-cols-2 min-h-full">

                <div className="grid-column pr-8 pt-32">

                    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                        
                        <h3 className="p-small font-alt uppercase text-lg mb-6 text-purple pl-1">MightyLabs DAO Presents</h3>
                        <h2 className="p-title font-alt text-5xl mb-12 text-gradient max-w-lg">Genesis Sheriff Badge</h2>
                        
                        <div className="mb-12">
                            The year is 2013, the blockchain landscape is best described as rugged and desolate, filled with hidden dangers and traps if you stray too far from the main path. Brave communities are moving in, daring to explore the uncharted territories, for they too have heard the whispers of legendary hidden treasures. The desires of striking it rich are too strong to resist and so, the new communities take the leap of faith and enter the new world, the world of the unknown, a world we call the New Frontier.
                            <br />
                            <br />
                            At first, the journey is one written as a tale of conquest, however, it is soon obvious that it is one of survival... The open land is unforgiving, the trail is harsh and isolate.  But as every good story goes, there must be a hero.  The lawlessness needed to be reigned in. The boundless opportunities stretched further than the eye can see. Unfortunately, dishonest thieves also saw a golden opportunity and started infiltrating the once impenetrable hope for a new era. The previously untarnishable battle cry, WAGMI, was dying - and people were losing faith in this new frontier.  Although a complete reset was not necessary, guidance and aid were much needed. A hero was sought after. A hero who could teach the lay of the land and escort individuals safety through the uncharted territories. A hero who was able to shred dishonesty and light the path of integrity toward success. This hero soon became a whisper of hope, ever-growing, ever strong… A faceless hero. A hero that went by one name and one name only, the MightyLabs DAO.
                            <br />
                            <br />
                            Here to tame the Wild Wild West of Web3.
                        </div>        
        
                        <div className="mb-12">
                            <ul className="diamond-list">
                                <li> Limited to 200 </li>
                                <li> Lifetime membership in the MightyLabs DAO</li>
                                <li> Voting rights, Profit share, Access to launchpad projects and more</li>
                                <li> Unique set of traits</li>
                                <li> Comes with an additional soul bound token - that cannot be gifted or sold</li>
                                <li> Limited to one per transaction</li>
                            </ul>
                        </div>

                        <div style={{height: 60}} className="pt-8 pb-12">
                            {infuraReady &&
                                <FadeIn>
                                    <div className="font-alt text-center mt-3">
                                        SOLD OUT
                                    </div>
                                </FadeIn>
                            }
                        </div>
                        
                        <div className="pb-24">
                            <FadeIn delay={1000}>
                                <Mint />
                            </FadeIn>
                        </div>
                    
                    </div>

                    
                </div>

                <div className="grid-column artwork-column">
                    
                    <div className="relative h-full artwork" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000">
                        <div className="parallax-container">
                            <div className="image-card relative" data-tilt data-tilt-full-page-listening style={{transformStyle: 'preserve-3d', transform: 'perspective(1000px)', width: 300, height: 300, borderRadius: 20, backgroundSize: 'cover', backgroundImage: `url(${heroImage})`}}>
                                <div className="image-card" style={{position: 'absolute', right: -160, top: -60, transform: 'translateZ(50px)', width: 200, height: 200, borderRadius: 15, backgroundSize: 'cover', backgroundImage: `url(${heroImage2})`}}></div>
                                <div className="image-card" style={{position: 'absolute', left: -90, bottom: -40, transform: 'translateZ(150px)', width: 150, height: 150, borderRadius: 15, backgroundSize: 'cover', backgroundImage: `url(${heroImage3})`}}></div>
                                <div className="image-card" style={{position: 'absolute', right: -60, bottom: -40, transform: 'translateZ(200px)', width: 90, height: 150, borderRadius: 15, backgroundSize: 'cover', backgroundImage: `url(${heroImage4})`}}></div>
                                <div className="image-card" style={{position: 'absolute', top: -60, left: -50, transform: 'translateZ(30px)', width: 80, height: 120, borderRadius: 15, backgroundSize: 'cover', backgroundImage: `url(${heroImage5})`}}></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            


            <div className="dynamic-bg">
                <div className="db-one"></div>
                <div className="db-two"></div>
                <div className="db-three"></div>
                <div className="db-four"></div>
                <div className="db-five"></div>
            </div> 

        

    </div>);

}
