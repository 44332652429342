import { useGlobal } from 'reactn';


export default function PageHome(props){

    const [onChainData] = useGlobal('onChainData');
    const [address] = useGlobal('address');

    return (<div className="pt-24">

        {address}

    </div>);

}