import { useEffect, useState, useGlobal } from 'reactn'; 
import { mint, mintWhitelist, startWalletConnection, networkIdToName, isAddressWhitelisted } from '../lib/blockchain';
import FadeIn from 'react-fade-in';

export default function Mint(props){

    const [onChainData] = useGlobal('onChainData');
    const [minting] = useGlobal('minting');
    const [chainId] = useGlobal('chainId');
    const [qty, setQty] = useState(1);
    const [status, setStatus] = useState('loading...');
    const [ready, setReady] = useState(false);


    const init = async () => {
        await setReady(true);
    }

    useEffect(() => {    
        init();
    }, []);

    var mintStatus;

    if(!chainId){
        mintStatus = 'connect_wallet';
    }else if(chainId != process.env.REACT_APP_NETWORK){
        mintStatus = 'wrong_network';
    }else if(!onChainData){
        mintStatus = 'loading';
    }else if(onChainData.mintCase == 'paused'){
        mintStatus = 'paused';
    }else if(onChainData.mintCase == 'soldout'){
        mintStatus = 'soldout';
    }else if(onChainData.mintCase == 'whitelist'){
        mintStatus = 'whitelist';
    }else{
        mintStatus = 'mint';
    }
    
    console.log('mintStatus', mintStatus);


    if(!ready && mintStatus != 'connect_wallet') return <div className="mx-auto relative text-center">{status}</div>;

    if(mintStatus == 'connect_wallet'){
        return (
            <div className="pt-12 text-center">
                <button onClick={() => startWalletConnection()} className="button">Connect your wallet</button>
            </div>);
    }

    return (
    
        <div className="mx-auto relative text-center p-12">

            {mintStatus == 'wrong_network' &&
                <div className="font-bold">Please switch to {networkIdToName(process.env.REACT_APP_NETWORK)}</div>
            }


            {mintStatus == 'paused' &&
                <FadeIn delay={300}>
                    <div className="font-bold mx-auto">
                        Mint not open yet
                    </div>
                </FadeIn>
            }

            {mintStatus == 'soldout' &&
                <div className="text-xl py-8 text-white mt-12 p-8 max-w-xl mx-auto">
                    <div className="text-xl text-indigo-400">We are sold out!</div>
                    <div>You can still grab one from OpenSea</div>
                </div>
            }

            {mintStatus == 'loading' &&
                <FadeIn>
                    <div className="font-bold">Loading</div>
                </FadeIn>
            }

            {mintStatus == 'whitelist' && !isAddressWhitelisted() &&
                <div style={{fontSize: '1.3em', color: '#f1ab27'}}>Sorry, you are not on the whitelist</div>
            }

            {(mintStatus == 'mint' || (mintStatus == 'whitelist' && isAddressWhitelisted())) &&
                <div>
                    
                    {!minting &&
                        <div>
                            
                            <div className="mt-2">
                                <FadeIn>
                                    <button onClick={() => {
                                        console.log(mintStatus);
                                        if(mintStatus == 'whitelist') mintWhitelist(qty, onChainData.price); // whitelist
                                        if(mintStatus == 'mint') mint(); // public
                                    }} className="button">Mint</button>
                                </FadeIn>

                            </div>
                        </div>
                    }
                    {!!minting && <div>Minting...</div>}
                </div>
            }

        </div>

    );

}
