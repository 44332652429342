import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// global storage 
setGlobal({
    address: null,
    chainId: null,
    contractAddress: null,
    loadingContract: false,
    loadingChain: false,
    nftMinted: false,
    remaining: null,
    minting: false,
    onChainData: null,
    approved: false,
    signature: null,

    backendReady: false,
    nextAirdrop: null,
    adminArtists: null,
    adminAirdrops: null,
    adminWhitelist: null,

    // fetched by infura
    maxSupply: 0,
    totalSupply: 0,
    infuraReady: false,

});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
