import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageHome from './pages/Home';
import PageMint from './pages/Mint';
import { initInfura } from './lib/blockchain';
import './scss/main.scss';


function App() {

    useEffect(() => {
        initInfura();
        AOS.init();
    }, []);

    return (
        <BrowserRouter>
            <div className="wrapper">

                <Toaster toastOptions={{ style: { color: '#000', fontWeight: 'bold' }, }} />

                <Routes>
                    <Route path="/" element={<PageMint />} />
                    <Route path="/home" element={<PageHome />} />
                </Routes>
                
            </div>
        </BrowserRouter>
    );
}

export default App;
